import * as React from 'react';
import { Socket } from 'socket.io-client';

export type WebSocketContextValues = {
  socket: Socket,
  connected: boolean,
  latency: number | undefined,
  goOnline(): void,
  goOffline(): void
}
export const WebSocketContext = React.createContext<WebSocketContextValues>({} as any);
export default WebSocketContext;