import * as React from 'react';
import { AudioManagerContext } from './AudioManagerContext';
import { Destination } from 'tone/build/esm';
import { Decibels } from 'tone/build/esm/core/type/Units';

type AudioManagerProviderProps = {
  initialVolume?: Decibels
}

export const AudioManagerProvider: React.FC<AudioManagerProviderProps> = (props) => {
  const {
    initialVolume = 0
  } = props;
  
  const [masterVolume, setMasterVolume] = React.useState<Decibels>(initialVolume);
  const [masterMute, setMasterMute] = React.useState<boolean>(false);

  const muteMaster = () => {
    setMasterMute(true);
  }

  const unMuteMaster = () => {
    setMasterMute(false);
  }

  const toggleMuteMaster = () => {
    setMasterMute(mute => !mute);
  }

  React.useEffect(() => {
    Destination.mute = masterMute;
    Destination.volume.rampTo(masterVolume);
    return () => {
      Destination.dispose();
    }
  }, []);

  return (
    <AudioManagerContext.Provider
      value={{
        masterVolume,
        masterMute,
        setVolume: setMasterVolume,
        muteMaster,
        unMuteMaster,
        toggleMuteMaster
      }}>
      {props.children}
    </AudioManagerContext.Provider>
  )
}

export default AudioManagerProvider;