import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import {
  Theme as MaterialUITheme,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton
} from '@material-ui/core';
import {MdMenu as MenuIcon} from 'react-icons/md';
import { useWebSocket } from 'contexts';

const StyledAppBar = styled(AppBar)<{ theme?: MaterialUITheme}>`
  background: ${props => props.theme.palette.primary.main};
` as typeof AppBar

export const Header = () => {
  
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            version
            socketInfo {
              protocol
              host
              port
            }
          }
        }
      }
    `
  );

  const { connected, goOnline, goOffline } = useWebSocket();

  return (
    <>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <link rel="icon" href="/favicon.ico" />
        <script defer src='https://static.cloudflareinsights.com/beacon.min.js' data-cf-beacon='{"token": "55b1c70b261b4229b5599fd0fbec5e24"}' />
      </Helmet>
      <StyledAppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6">
            Synthesize ONLINE
          </Typography>
          <Button color="inherit" onClick={() => { connected ? goOffline() : goOnline() }}>{connected ? 'Go offline' : 'Go online'}</Button>
        </Toolbar>
      </StyledAppBar>
    </>
  )
};
export default Header;