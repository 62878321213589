import * as React from 'react';
import {
  Box,
  Container
} from '@material-ui/core';

export const Footer = () => (
  <Box component="footer">
    <Container>
      <span>footer</span>
    </Container>
  </Box>
)

export default Footer;