import React from "react"
import { WrapRootElementNodeArgs } from 'gatsby';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from 'theme';
import { AudioManagerProvider, WebSocketProvider } from 'contexts';

const wrapRootElement = ({ element }: WrapRootElementNodeArgs) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AudioManagerProvider>
      <WebSocketProvider>
        {element}
      </WebSocketProvider>
    </AudioManagerProvider>
  </ThemeProvider>
)

export default wrapRootElement