import * as React from 'react';
import Header from './header';
import Footer from './footer';
import { Container } from '@material-ui/core';

export const Layout: React.FC = ({children}) => (
  <>
    <Header />
      <Container component="main">
        {children!}
      </Container>
    <Footer />
  </>
)

export default Layout;