import * as React from 'react';
import { WebSocketContext } from './WebSocketContext';
import { io, Socket } from 'socket.io-client';
import { usePrevious } from 'utils';

type WebSocketProviderProps = {
  socketUrl?: string
}

type ConnectionState = {
  connect: boolean,
  connected: boolean,
  latency: number
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = (props) => {
  const {
    socketUrl = 'https://ws.synthesize.online'
  } = props;
  
  const [socket, setSocket] = React.useState<Socket>(io(socketUrl, {autoConnect: false}));
  const [connection, setConnection] = React.useState<ConnectionState>({
    connect: false,
    connected: socket.connected,
    latency: 0
  });
  
  const previousConnection = usePrevious(connection);

  const setConnectionState = (newValues: Partial<ConnectionState>) => {
    setConnection(connection => {
      return {
        ...connection,
        ...newValues
      };
    });
  }

  const goOnline = () => {
    setConnectionState({
      connect: true
    });
  }

  const goOffline = () => {
    setConnectionState({
      connect: false
    });
  }

  React.useEffect(() => {
    // @ts-ignore: Object is possibly 'null'.
    if (connection.connect !== previousConnection?.connect) {
      connection.connect ? socket.connect() : socket.disconnect();
    }
    return () => {
      socket.disconnect();
    }
  }, [connection.connect]);

  React.useEffect(() => {
    socket.on('connect', () => {
      console.log('connected to survur, got id:', socket.id);
      setConnectionState({
        connected: socket.connected
      });
    });
  
    socket.on('disconnect', () => {
      console.log('disconnected from survur');
      setConnectionState({
        connected: socket.connected
      });
    });
    const pingPong = () => {
      const start = Date.now();
      socket.volatile.emit("ping", () => {
        const latency = Date.now() - start;
        console.log('latency:',latency);
        setConnectionState({
          latency
        });
      });
    }
    const latencyCheck = setInterval(pingPong, 5000);
    return () => {
      clearInterval(latencyCheck);
      socket.off('connect');
      socket.off('disconnect');
      socket.disconnect();
    }
  }, []);

  return (
    <WebSocketContext.Provider
      value={{
        socket,
        connected: socket.connected,
        latency: connection.latency,
        goOnline,
        goOffline
      }}>
      {props.children}
    </WebSocketContext.Provider>
  )
}

export default WebSocketProvider;