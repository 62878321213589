import * as React from 'react';
import { Decibels } from 'tone/build/esm/core/type/Units';

export type AudioManagerContextValues = {
  masterVolume: Decibels,
  masterMute: boolean,
  setVolume(volume: Decibels): void,
  muteMaster(): void,
  unMuteMaster(): void,
  toggleMuteMaster(): void
}
export const AudioManagerContext = React.createContext<AudioManagerContextValues>({} as any);
export default AudioManagerContext;